const allowedHosts = {
    'test.lodgingpartner.expedia.com': 'test',
    'localhost:8080': 'development',
    '127.0.0.1:8080': 'development',
    'prod.lodgingpartner.expedia.com': 'production',
    'developers.expediagroup.com': 'production',
};

function getEnv(host) {
    for (const [key, value] of Object.entries(allowedHosts)) {
        if (host === key || host.endsWith(key)) {
            return value;
        }
    }
    return 'test';
}

module.exports = {
    getEnv,
};
