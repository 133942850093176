import React from 'react';

const Footnote = () => {
    return (
        <>
            <div className="cpp-page__footnote">
                <p>
                    1 The list of benefits offered in the program is subject to
                    change by Expedia Group and benefits with additional
                    qualifying criteria or availability considerations are
                    noted.
                </p>
                <p>
                    2 Expedia Group will determine in its sole discretion
                    whether any API or capability is offered to connectivity
                    partners qualified to receive this benefit.
                </p>
                <p>
                    3 Expedia Group will determine in its sole discretion
                    whether connectivity partners are accepted or can remain in
                    the program.
                </p>
                <p>
                    4 &quot;Distribution&quot; refers to sending Availability
                    and Rates and receiving Bookings.
                </p>
            </div>
        </>
    );
};

export default Footnote;
