import React from 'react';
import {triggerEdapButtonClick, eventCategories} from '../../util/edap';

const QualificationSection = () => {
    return (
        <>
            <div className="cpp-page__content__qualification">
                <h2 className="section-heading">How to qualify</h2>
                <p>
                    Connectivity partners can secure Elite or Preferred status
                    in the 2026 Connectivity Partner Program by reaching
                    specific goals and business and technical metrics under the
                    categories listed.
                    <sup>3</sup>
                </p>
                <div className="cpp-page__content__qualification-details">
                    <div className="section-heading">Qualifying metrics</div>
                    <table className="table table-responsive">
                        <tbody>
                            <tr>
                                <th>
                                    <strong>Category</strong>
                                </th>
                                <th>
                                    <strong>Metric</strong>
                                </th>
                            </tr>
                            <tr>
                                <td>Production / Growth</td>
                                <td>
                                    Connected Properties NBV & Connected
                                    Properties NBV Growth (YoY) Fair Share
                                    Market Index
                                </td>
                            </tr>
                            <tr>
                                <td>Partner engagement quality </td>
                                <td>
                                    Overall Partnership with EG through Annual
                                    Survey, Leadership engagement, Tech
                                    engagement, and commitment to partner with
                                    EG
                                </td>
                            </tr>
                            <tr>
                                <td>Connection experience</td>
                                <td>
                                    Capability adoption and Capability Success
                                    Rates
                                </td>
                            </tr>
                            <tr>
                                <td>Marketplace enhancements</td>
                                <td>
                                    Commercial and Technical Optimization
                                    Initiatives
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="cpp-minimum-requirements-wrapper">
                        <div className="section-heading">
                            2026 Connectivity Partner Program minimum
                            requirements
                        </div>
                        <p>
                            System type requirements: Property Distribution
                            Software
                            <sup>4</sup>
                        </p>
                        <p>
                            Technical requirements: Availability/Rates API with
                            support for Expedia Traveler Preference (ETP),
                            Booking Retrieval/ Notification API with support for
                            Expedia Virtual Card (EVC) and Value Add Promotions
                            (VAP), PCI compliance (where applicable)
                        </p>
                        <p>
                            Commercial requirements: Expedia Group API License
                            Agreement acceptance, Registration for{' '}
                            <a href="https://partner.expediagroup.com/en-us/resources/blog/expedia-voices-community">
                                Expedia Voices
                            </a>
                            .
                        </p>
                    </div>
                    <div className="cpp-timeline-wrapper">
                        <div className="section-heading">Timeline</div>
                        <p>
                            The Connectivity Partner Program will operate on an
                            annual basis:
                        </p>
                        <ul className="timeline-list">
                            <li>December 2024: Program criteria announced</li>
                            <li>
                                January 1 – December 31, 2025: Program
                                qualification measurement period for 2025
                            </li>
                            <li>
                                September 30, 2024: Deadline to submit
                                capability adoption certification requests
                            </li>
                            <li>
                                November 30, 2025: Deadline for successful
                                certification completion
                            </li>
                            <li>
                                December 13, 2025: Deadline for successful
                                optimization initiative completion for credit
                            </li>

                            <li>
                                Q1 2026 – Elite and Preferred partners announced
                                for 2026 program
                            </li>
                        </ul>
                    </div>
                    <p className="current-cpp-list">
                        <a
                            onClick={triggerEdapButtonClick(
                                eventCategories.CONTENT_LINKS,
                                'Current Connectivity Partner list'
                            )}
                            href="https://www.expediapartnercentral.com/connectivity"
                        >
                            See the current list of Elite and Preferred
                            Connectivity Partners
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default QualificationSection;
