import React from 'react';

const BenefitsBanner = () => {
    return (
        <>
            <div className="cpp-page__benefits-banner">
                <div className="section-heading">Get exclusive benefits</div>
                <div className="section-content">
                    <p>
                        For the 2026 Connectivity Partner Program, both Elite
                        and Preferred partners will be eligible for the
                        following potential benefits, unless otherwise noted.
                        <sup>1</sup>
                    </p>
                </div>

                <div className="benefits-banner__options">
                    <div className="benefits-banner__option">
                        <h3>Business advantages</h3>
                        <ul>
                            <li>
                                Enhanced account management and technical
                                support
                            </li>
                            <li>Business reviews</li>
                            <li>Exclusive financial incentive program</li>
                        </ul>
                    </div>
                    <div className="benefits-banner__option">
                        <h3>Product collaboration &amp; first access</h3>
                        <ul>
                            <li>
                                Early access to APIs and capabilities
                                <sup>2</sup>
                            </li>
                            <li>Product reviews</li>
                        </ul>
                    </div>
                    <div className="benefits-banner__option">
                        <h3>Co-marketing and promotion</h3>
                        <ul>
                            <li>
                                Promotion as Preferred or Elite Connectivity
                                Partner
                            </li>
                            <li>
                                Offers on your software solution via Provider
                                Guide
                            </li>
                            <li>Lead capture capability via Provider Guide</li>
                            <li>
                                Featured partner placement on Provider Guide{' '}
                            </li>
                            <li>Enhanced profile on Provider Guide</li>
                            <li>
                                Eligibility for sponsorship of partner events
                            </li>
                            <li>
                                Eligibility for sponsoring Expedia Group market
                                events
                            </li>
                            <li>Exclusive event invitations</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenefitsBanner;
